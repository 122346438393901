import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../Icons'
import AppContext from 'AppContext'

const baseUrl = (operatorID) => (
  '/operators/' + operatorID
)

const ManagementLinks = (operatorID) => {
  const links = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: 'house'
    },
    {
      title: 'Locations',
      path: baseUrl(operatorID) + '/locations',
      icon: 'location'
    },
    {
      title: 'Memberships',
      path: baseUrl(operatorID) + '/members',
      icon: 'customers',
      permission: 'memberships.member.list'
    },
    {
      title: 'Memberships (BETA)',
      path: baseUrl(operatorID) + '/memberships',
      icon: 'customers',
      permission: 'memberships.member.list'
    },
    {
      title: 'Free passes',
      path: baseUrl(operatorID) + '/free-passes',
      icon: 'freePasses',
      permission: 'memberships.member.list' // TODO: Fix permission
    },
    {
      title: 'Paid passes',
      path: baseUrl(operatorID) + '/paid-passes',
      icon: 'freePasses',
      permission: 'memberships.member.list' // TODO: Fix permission
    },
    {
      title: 'PTs',
      path: baseUrl(operatorID) + '/pts/by-location', // not making Vaadin redirect from / work, we're falling back on linking directly to 1st view
      icon: 'pts',
      permission: 'memberships.member.list' // TODO: Fix permission
    },
    {
      title: 'Reports',
      path: baseUrl(operatorID) + '/reports',
      icon: 'reports',
      permission: 'memberships.member.list' // TODO: Fix permission
    },
    {
      title: 'Product',
      path: baseUrl(operatorID) + '/product/details',
      icon: 'product',
      activePaths: ['product']
    },
    {
      title: 'Add-ons',
      path: baseUrl(operatorID) + '/add-ons/pt-packages',
      icon: 'product',
      activePaths: ['add-ons']
    },
    {
      title: 'Promotions',
      path: baseUrl(operatorID) + '/promotions-v2/sales/list',
      icon: 'promotion'
    },
    {
      title: 'Maintenance',
      path: baseUrl(operatorID) + '/maintenance/issues/list',
      icon: 'maintenance'
    }
  ]

  // only show in CH and DE
  if (['4de2a7cb-bd28-4111-aa28-4bbcdf60f533','c04bc7c3-903f-4804-a952-9abc0016b824'].includes(operatorID)) {
    const leads = {
      title: 'Leads',
      path: baseUrl(operatorID) + '/leads',
      icon: 'customers',
      permission: 'memberships.member.list'
    }
    links.splice(11, 0, leads)
  }

  // Only show wellpass in DE
  if (['c04bc7c3-903f-4804-a952-9abc0016b824'].includes(operatorID)) {
    links.splice(12, 0, {
      title: 'Wellpass',
      path: baseUrl(operatorID) + '/qualitrain-wellpass',
      icon: 'customers',
      permission: 'memberships.member.list'
    })
  }

  return links
}

const SystemLinks = (operatorID) => (
  [
    {
      title: 'Settings',
      path: baseUrl(operatorID) + '/settings',
      icon: 'settings'
    },
    {
      title: 'Administrators',
      path: baseUrl(operatorID) + '/administrators',
      icon: 'lock'
    },
    {
      title: 'Development',
      path: baseUrl(operatorID) + '/development',
      icon: 'lock'
    }
  ]
)

const links = (operatorID) => (
  [
    {
      header: 'MANAGEMENT',
      links: ManagementLinks(operatorID)
    },
    {
      header: 'SYSTEM',
      links: SystemLinks(operatorID)
    }
  ]
)

const LinkItem = ({link, method}) => {
  // If a link has an array of activePaths
  // then we override the isActive and checks
  // if we find the routes included
  const checkActive = (match, location) => {
    const locArray = location.pathname.split('/')
    let matchingPath = locArray.filter(
      p => link.activePaths.includes(p)
    )

    return matchingPath.length > 0
  }

  return (
    <NavLink
      to={link.path}
      onClick={() => method(link.name)}
      activeClassName='active'
      isActive={link.activePaths ? checkActive : null}
    >
      <li>
        <Icon type={link.icon} />
        {link.title}
      </li>
    </NavLink>
  )
}

class SideBar extends Component {
  render () {
    // console.log('Sidebar props', this.props)

    const mobileNavClass = this.props.mobile_nav_hidden ? 'hidden' : 'visible'
    return (
      <div id='sidebar' className={mobileNavClass}>
        <ul>
          {
            /*
            <li className='search'>
              <Icon type='search' />
              Search..
            </li>
             */
          }
          { this._linkGroups() }
          {/* Logout link */}
          <li onClick={this.props.logout} style={{ cursor: 'pointer' }}>
            <Icon type={'trash'} />
            Logout
          </li>
        </ul>
      </div>
    )
  }

  // NOTE: setting `betaUsers: [email_list]` in XxxLinks will allow selective access to the sidebar item
  _hasBetaAccess(link) {
    if (!link.betaUsers || link.betaUsers.length === 0) {
      return true
    }
    const user = JSON.parse(localStorage.getItem("currentAdmin"))
    if (!user || (user && link.betaUsers.includes(user.username))) {
      return true
    }
    return false
  }

  _hasAccess (link) {
    if (!this._hasBetaAccess(link)) {
      return false
    }
    if (!link.permission) return true
    if (!this.props.permissions) {
      return false
    }
    return this.props.permissions.includes(link.permission)
  }

  _linkGroups = () => {
    const context = this.context // seem to be required lo load context _before_ retrieving operator ID
    return (
      links(context.operator.id).map((group) => {
        return (
          [
            <li key={group.header} className='header'>
              {group.header}
            </li>,
            group.links.filter(link => this._hasAccess(link)).map((link) =>
              <LinkItem
                key={link.title}
                link={link}
                method={this.props.toggle_mobile_nav}
              />
            )
          ]
        )
      })
    )
  }
}

SideBar.contextType = AppContext

export default SideBar
