const Concept = ({ systemConcept, humanConcept }) => {
  let result

  if (humanConcept) {
    result = humanConcept
  } else {
    // TODO: Missing translation of system concept to human readable strings
    switch (systemConcept) {
      case 'base_service_fee':
        result = 'Monthly fee'
        break
      default:
        result = 'Unknown'
    }
  }

  return result
}

export default Concept
