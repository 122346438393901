import React from 'react'
import AppContext from 'AppContext'

const RevenueForecast = (props) => {
  let env = "staging"
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = "production";
  }

  const token = window.localStorage.getItem('auth-token')
  const { operator } = React.useContext(AppContext)

  // We may have to wait for AppContext to be available
  if(!operator) {
    return (
      <div className='index sales-promotions'>
      </div>
    )
  }

  // Must leave empty h1 there for element below not to be cropped...
  return (
    <div className='index sales-promotions'>
      <h1></h1>
      <cl-membership-revenue-forecast
        mountpath={`/operators/${operator.id}/memberships`}
        apihost={`https://memberships.${env}.credlock.net`}
        currency={operator.currency}
        token={token}
      >
      </cl-membership-revenue-forecast>
    </div>
  )
}

export default RevenueForecast
