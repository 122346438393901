import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumbs = (props) =>
  <span className='breadcrumbs'>
    <Link to={props.baseURL}>
      Customers
    </Link> >&nbsp;
    <Link to={props.baseURL}>
      Members
    </Link>
    { props.children }
  </span>

export default BreadCrumbs
