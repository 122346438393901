import React from 'react'
import { StatusBadge } from 'components/Badges/index'
import { ActionButton } from 'components/Buttons/index'
import { Link } from 'react-router-dom'
import { NoAccessPage } from '../../../../../NoAccess'
import MemberRouteAndNavigation from './MemberNavigation'

import BreadCrumbsComponent from '../components/BreadCrumbs'
import SupportPinModal from './SupportPinModal'
import { memberToken } from '../../../../../../services/api/members'
import selectConfig from '../../../../../../config'

const baseURL = (props) => `/operators/${props.match.params.operatorID}/members`

const MemberName = ({ member }) => {
  const maxLength = 31
  const statusLength = member.status.length / 2

  const name = (member.customer.first_name + ' ' + member.customer.last_name)

  if (name.length + statusLength > maxLength) {
    return name.slice(0, maxLength - statusLength) + '...'
  } else {
    return name
  }
}

const BreadCrumbs = (props) => {
  return (
    <BreadCrumbsComponent baseURL={baseURL(props)}>
      <MemberNameBreadcrumb {...props} />
    </BreadCrumbsComponent>
  )
}

const editPersonaliaRoute = ({ match, location }) => {
  const { operatorID, memberID } = match.params
  const { pathname } = location
  return pathname === `/operators/${operatorID}/members/${memberID}/edit`
}

const manageMemberRoute = (props) => {
  const { operatorID, memberID } = props.match.params
  const { pathname } = props.location
  return pathname.startsWith(
    `/operators/${operatorID}/members/${memberID}/manage`
  )
}

const SubPage = (props) => {
  return editPersonaliaRoute(props) || manageMemberRoute(props)
}

const MemberNameBreadcrumb = (props) => {
  if (!SubPage(props)) return null

  const { operatorID, memberID } = props.match.params
  const path = `/operators/${operatorID}/members/${memberID}`
  return (
    <Link to={path}>
      &nbsp;>&nbsp;
      <MemberName member={props.member} />
    </Link>
  )
}

const MemberLoginButton = ({ member }) => {
  if (!member) return null

  const success = (result) => {
    // redirect to mypage
    console.log('success!')
    const redirectTo = selectConfig(member.operator_id).mypage.url + '?access_token=' + result.token
    console.log(redirectTo)
    window.open(redirectTo, '_blank')
  }

  const loginMember = (member) => {
    // console.log('login', member)
    memberToken(member.id, member.operator_id, success)
  }

  return (
    <div className="header-action-wrapper">
      <ActionButton
        onClick={() => loginMember(member)}
        txt="View my page"
        icon="report white"
      />
    </div>
  )
}

const SupportPinButton = ({ member, status, setActive }) => {
  // Not necessary to display the SupportPin option
  // unless the membership has a status that grants access to locations
  if (!['active', 'in_trial', 'in_cancellation'].includes(status)) return null

  if (!member) return null

  return (
    <div className="header-action-wrapper">
      <ActionButton
        onClick={() => setActive(true)}
        txt="Send support pin"
        icon="mailsend white"
      />
    </div>
  )
}

const SupportPinModalWrapper = ({ active, member, onClose }) => {
  if (!active) return null

  return (
    <SupportPinModal membership={member} onClose={onClose} active={active} />
  )
}

const MemberMainPage = (props) => {
  const [supportPinActive, setSupportPinActive] = React.useState(false)

  const { member } = props

  if (!Object.keys(member).length > 0) {
    return <NoAccessPage />
  }

  return (
    <div>
      <h1 className={'with-breadcrumbs'}>
        <BreadCrumbs {...props} />
        <MemberName member={member} />
        <span style={{ paddingLeft: '10px' }}>
          <StatusBadge status={member.status} />
          { member.cancellation && member.cancellation.comment ? <span style={{ fontSize: '0.9rem', marginLeft: '1rem'}}>{member.cancellation.comment}</span> : '' }
        </span>

        <MemberLoginButton member={member} />

        <SupportPinButton
          status={member.status}
          member={member}
          setActive={setSupportPinActive}
        />

      </h1>
      <SupportPinModalWrapper
        member={member}
        onClose={() => setSupportPinActive(false)}
        active={supportPinActive}
      />
      <MemberRouteAndNavigation {...props} />
    </div>
  )
}

const header = (props) => {
  if (editPersonaliaRoute(props)) {
    return 'Edit Personalia'
  } else {
    return 'Manage membership'
  }
}

const SubPageTopBar = ({ path, index, props }) => {
  const subPageHeader = header(props)
  return (
    <div>
      <h1 className={'with-breadcrumbs'}>
        <BreadCrumbs {...props} />
        {subPageHeader}
      </h1>
      <MemberRouteAndNavigation subPage={subPageHeader} {...props} />
    </div>
  )
}

const MemberWrapper = (props) => {
  if (SubPage(props)) {
    return <SubPageTopBar path="manage" index="General" props={props} />
  }
  return <MemberMainPage {...props} />
}

export default MemberWrapper
